import state from './temp.state'
import mutations from './temp.mutations'
import actions from './temp.actions'
import getters from './temp.getters'

export default {
  state,
  mutations,
  actions,
  getters
}
