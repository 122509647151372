<template>
  <div class="navbar">
    <div class="right-menu">
      <router-link class="right-menu-item" to="/">{{ $t('navbar.dashboard') }}</router-link>
      <a style="display:block;" class="right-menu-item" @click="logOut">{{ $t('navbar.logout') }}</a>
      <router-link to="/settings/my-account" class="avatar-container right-menu-item">
        <span v-if="user" class="user-title">{{ user.name }}</span>
        <div class="avatar-wrapper">
          <img v-if="user && user.file" :src="user.file.url_view" class="avatar user-avatar">
          <img v-else src="@/assets/custom-theme/default-avatar.png" class="user-avatar">
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import LangSelect from '@/components/LangSelect'
// import ThemePicker from '@/components/ThemePicker'
// import Screenfull from '@/components/Screenfull'
// import SizeSelect from '@/components/SizeSelect'
// import Breadcrumb from '@/components/Breadcrumb'
// import EntityList from '../../../pages/components/core/EntityList'
// import ErrorLog from '@/components/ErrorLog'
// import Hamburger from '@/components/Hamburger'

export default {
  components: {
    // EntityList,
    // Breadcrumb,
    // Hamburger,
    // ErrorLog,
    // Screenfull,
    // SizeSelect,
    // LangSelect,
    // ThemePicker
  },
  data() {
    return {
      previewFileUrl: null
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'user',
      'device'
    ])
  },
  methods: {
    logOut() {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 50px;
  line-height: 50px;
  border-radius: 0px !important;
  .user-avatar-red {
    border-bottom: 5px solid red;
  }
  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }
  .breadcrumb-container{
    float: left;
  }
  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }
  .right-menu {
    float: right;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;

    .user-title {
      margin-right: 11px;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: right;
    }

    &:focus{
     outline: none;
    }
    .right-menu-item {
      display: inline-block;
      margin: 0 13px;
      font-family: SourceSansPro, sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: -0.2px;
      color: #ffffff;
    }
    .screenfull {
      height: 20px;
    }
    .international{
      vertical-align: top;
    }
    .theme-switch {
      vertical-align: 15px;
    }
    .avatar-container {
      display: flex;
      flex-direction: row;
      margin-right: 30px;
      .avatar-wrapper {
        max-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }
        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
