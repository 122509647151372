<template>
  <el-scrollbar wrap-class="scrollbar-wrapper">
    <el-menu
      ref="elMenu"
      :show-timeout="200"
      :default-active="$route.path"
      :collapse="true"
      :default-openeds="[0]"
      mode="horizontal"
      unique-opened
      menu-trigger="click"
      @open="menuOpen">
      <template v-for="route in routes">
        <sidebar-item
          v-if="route.meta && ((!route.meta.admin || canUseAdminDashboard) && (!route.meta.subscription || canUseDashboard))"
          :key="route.path"
          :item="route"
          :base-path="route.path"/>
      </template>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex'
import { dataGet } from '@fps/vue-toolkit/dist/util'
import SidebarItem from './SidebarItem'

export default {
  components: { SidebarItem },
  computed: {
    ...mapGetters([
      'sidebar',
      'canUseDashboard',
      'canUseAdminDashboard'
    ]),
    routes() {
      return [...this.$router.options.routes]
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  mounted() {
    this.sidebar.opened = false
  },
  methods: {
    menuOpen(path) {
      if (dataGet('$route.matched', this, []).length && this.$route.matched[0].path !== path) {
        this.$router.push(path)
      }
      this.sidebar.opened = true
    }
  }
}
</script>
