export default {
  modalShow: false,
  appointmentDate: [0, 7, 10, 14],
  daily_emails: [],
  case_emails: [],
  timeZones: [
    {
      description: '(UTC-12:00) International Date Line West',
      gtm: '-12:00'
    },
    {
      description: '(UTC-11:00) Coordinated Universal Time-11',
      gtm: '-11:00'
    },
    {
      description: '(UTC-10:00) Hawaii',
      gtm: '-10:00'
    },
    {
      description: '(UTC-09:30) Marquesas Islands',
      gtm: '-09:30'
    },
    {
      description: '(UTC-09:00) Alaska',
      gtm: '-09:00'
    },
    {
      description: '(UTC-08:00) Pacific Time (US & Canada)',
      gtm: '-08:00'
    },
    {
      description: '(UTC-07:00) Mountain Time (US & Canada)',
      gtm: '-07:00'
    },
    {
      description: '(UTC-06:00) Central Time (US & Canada)',
      gtm: '-06:00'
    },
    {
      description: '(UTC-05:00) Eastern Time (US & Canada)',
      gtm: '-05:00'
    },
    {
      description: '(UTC-04:00) Atlantic Time (Canada)',
      gtm: '-04:00'
    },
    {
      description: '(UTC-03:30) Newfoundland',
      gtm: '-03:30'
    },
    {
      description: '(UTC-03:00) Greenland',
      gtm: '-03:00'
    },
    {
      description: '(UTC-02:00) Coordinated Universal Time-02',
      gtm: '-02:00'
    },
    {
      description: '(UTC-01:00) Azores',
      gtm: '-01:00'
    },
    {
      description: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
      gtm: '+00:00'
    },
    {
      description: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
      gtm: '+01:00'
    },
    {
      description: '(UTC+02:00) Athens, Bucharest',
      gtm: '+02:00'
    },
    {
      description: '(UTC+03:00) Istanbul',
      gtm: '+03:00'
    },
    {
      description: '(UTC+03:30) Tehran',
      gtm: '+03:30'
    },
    {
      description: '(UTC+04:00) Abu Dhabi, Muscat',
      gtm: '+04:00'
    },
    {
      description: '(UTC+04:30) Kabul',
      gtm: '+04:30'
    },
    {
      description: '(UTC+05:00) Islamabad, Karachi',
      gtm: '+05:00'
    },
    {
      description: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
      gtm: '+05:30'
    },
    {
      description: '(UTC+05:45) Kathmandu',
      gtm: '+05:45'
    },
    {
      description: '(UTC+06:00) Astana',
      gtm: '+06:00'
    },
    {
      description: '(UTC+06:30) Yangon (Rangoon)',
      gtm: '+06:30'
    },
    {
      description: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
      gtm: '+07:00'
    },
    {
      description: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
      gtm: '+08:00'
    },
    {
      description: '(UTC+08:45) Eucla',
      gtm: '+08:45'
    },
    {
      description: '(UTC+09:00) Osaka, Sapporo, Tokyo',
      gtm: '+09:00'
    },
    {
      description: '(UTC+09:30) Darwin',
      gtm: '+09:30'
    },
    {
      description: '(UTC+10:00) Canberra, Melbourne, Sydney',
      gtm: '+10:00'
    },
    {
      description: '(UTC+10:30) Lord Howe Island',
      gtm: '+10:30'
    },
    {
      description: '(UTC+11:00) Bougainville Island',
      gtm: '+11:00'
    },
    {
      description: '(UTC+12:00) Coordinated Universal Time+12',
      gtm: '+12:00'
    },
    {
      description: '(UTC+12:45) Chatham Islands',
      gtm: '+12:45'
    },
    {
      description: '(UTC+13:00) Coordinated Universal Time+13',
      gtm: '+13:00'
    },
    // {
    //   description: '(UTC+14:00) Kiritimati Island',
    //   gtm: '+14:00'
    // }
  ],
  subscriptionStatuses: [
    { id: 'STATUS_TRIAL', name: ('Status trial') },
    { id: 'ACTIVE_PAYMENT', name: ('Active payment') },
    { id: 'ACTIVE_CANCELED', name: ('Active canceled') },
    { id: 'INACTIVE_PAYMENT', name: ('Inactive payment') },
    { id: 'INACTIVE_CANCELED', name: ('Inactive canceled') },
    { id: 'INACTIVE_EXPIRED', name: ('Inactive expired') }
  ],
  activeStatuses: ['ACTIVE_PAYMENT', 'ACTIVE_CANCELED', 'STATUS_TRIAL']
}
