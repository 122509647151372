import mutations from './temp.mutationlist'

export default {
  fetchDoctors: ({ state, commit }) => {
    if (state.doctors) {
      return Promise.resolve(state.doctors)
    }
    return new Promise((resolve, reject) => {
      this.http.get('doctors')
        .then((response) => {
          if (response && response.data) {
            commit(mutations.CHANGE_DOCTORS, response && response.data ? response.data : [])
          }
          resolve(state.doctors)
        }, (error) => {
          reject(error)
        })
    })
  }
}
