import { Subject } from 'rxjs'

export default {
  roles: [],
  messageSubject: new Subject(),
  iframe: '',
  otherWindow: '',
  data: '',
  messageReceivedBind: null,

  install(Vue, options) {
    Vue.prototype.$payment = this
    this.messageReceivedBind = this.onMessageReceived.bind(this)
  },

  init(iframe, otherWindow) {
    this.setOtherWindow(iframe, otherWindow)
    window.removeEventListener('message', this.messageReceivedBind, false)
    // Listen to messages
    window.addEventListener('message', this.messageReceivedBind, false)
  },

  setOtherWindow(iframe, otherWindow) {
    // console.log(iframe)
    this.iframe = iframe

    this.otherWindow = otherWindow || (this.iframe.contentWindow || this.iframe.contentDocument)
  },

  onMessageReceived(event) {
    // Parse the event data
    // console.log(this)
    let data
    try {
      data = JSON.parse(event.data)
      this.messageSubject.next(data)
    } catch (e) {
      // console.log('message', e)
    }
    // console.log('onMessageReceived', data)
  },

  sendRequests(message) {
    // console.log('sendRequests', message, this.iframe, this.otherWindow)
    this.otherWindow.postMessage(JSON.stringify(message), '*')
  },

  asObservable() {
    return this.messageSubject.asObservable()
  }
}

