/* eslint-disable no-param-reassign */
import Vue from 'vue'
import _ from 'lodash-es'
import mutations from './mutationlist'
import { getInitialState } from '../store'

/* eslint no-param-reassign: [
  "error", { "props": true, "ignorePropertyModificationsFor": ["state"] }
] */
export default {
  [mutations.SET_DEEP]: (state, { key, value }) => {
    state = _.mergeWith(
      state,
      key.split('.').reverse().reduce((o, i) => ({ [i]: o }), value),
      (objValue, srcValue) => {
        if (_.isArray(srcValue)) {
          return srcValue
        }

        return undefined
      },
    )
  },

  [mutations.MODAL_SHOW]: (state, payload) => {
    Vue.set(state, 'modalShow', payload)
  },

  [mutations.RESET]: (state, payload) => {
    Object.assign(state, getInitialState(), {
      config: state.config
    })
  }
}
