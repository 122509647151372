export default {
  getTableFilters(state) {
    var filters = []

    if (state.search) {
      filters.push({
        property: 'keywordFilter',
        operator: 'scope',
        value: state.search
      })
    }

    if (state.location) {
      filters.push({
        property: 'locationFilter',
        operator: 'scope',
        value: {
          longitude: state.location.geometry.location.lng(),
          latitude: state.location.geometry.location.lat(),
          radius: 20
        }
      })
    }

    if (state.specialities && state.specialities.length) {
      const filterSpecialities = state.specialities
        .filter(item => !!item)
        .map(item => item.id)

      if (filterSpecialities.length) {
        filters.push({
          property: 'specialities.id',
          operator: 'IN',
          value: filterSpecialities
        })
      }
    }

    if (state.hourlyRate) {
      filters = [
        ...filters,
        ...[{
          property: 'profile.hourly_rate',
          operator: '>=',
          value: state.hourlyRate[0]
        }, {
          property: 'profile.hourly_rate',
          operator: '<=',
          value: state.hourlyRate[1]
        }]
      ]
    }

    return filters
  }
}
