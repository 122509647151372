/* eslint-disable no-new,no-unused-vars */
import Vue from 'vue'

import Cookies from 'js-cookie'

import * as VueGoogleMaps from 'vue2-google-maps'
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css'
import MintUI from 'mint-ui'
import '@/styles/index.scss' // global css
import FPS from '@fps/vue-toolkit'
import App from './App'
import router from './router'
import store from './store'

import i18n from './lang' // Internationalization
import payment from './api/payment.service'
import './icons' // icon
import './errorLog' // error log
import './permission' // permission control
// import './mock' // simulation data

import * as filters from './filters' // global filters

Vue.use(Element, { locale })
Vue.use(MintUI)
Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

Vue.use(FPS, {
  // propagateState: false,
  localStorageStateKey: 'alignerTrack',
  router,
  excludedStates: ['temp', 'filters', 'app'],
  registerExtra(config) {
  // Init google maps
    if (config.googleMapsKey) {
      Vue.use(VueGoogleMaps, {
        load: {
          key: config.googleMapsKey,
          libraries: 'places,drawing,visualization'
        },
        autobindAllEvents: true
      })
    }
  }
})

Vue.use(payment)

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
