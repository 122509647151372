<template>
  <div>
    <el-button :class="{'is-active':isActive}" @click="toggleClick">
      <i :class="'icon-'+ icon"/>
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'Hamburger',
  props: {
    isSubMenu: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    }
  },
  methods: {
    toggleClick() {
      this.isSubMenu ? this.$store.dispatch('toggleSideBar') : ''
    }
  }
}
</script>

<style scoped>
  .hamburger {
    display: inline-block;
    cursor: pointer;
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
    transition: .38s;
    transform-origin: 50% 50%;
  }

  .hamburger.is-active {
    transform: rotate(0deg);
  }
</style>
