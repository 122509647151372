import { dataGet } from '@fps/vue-toolkit/dist/util'
import { cloneDeep } from 'lodash-es'

export default {
  canUseDashboard(state) {
    return !!cloneDeep(dataGet('user.roles', state, []))
      .filter(item => ['tenant', 'admin'].indexOf(item.key) !== -1).length
  },
  canUseAdminDashboard(state) {
    return !!cloneDeep(dataGet('user.roles', state, []))
      .filter(item => ['admin'].indexOf(item.key) !== -1).length
  }
}
