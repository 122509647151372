export default {
  filters: {
    patient: null,
    doctor: null,
    location: null,
    appointment_date: null,
    appointment_type: null,
    status: null
  }
}
