import state from './filters.state'
import mutations from './filters.mutations'
import actions from './filters.actions'
import getters from './filters.getters'

export default {
  state,
  mutations,
  actions,
  getters
}
