import Vue from 'vue'
import mutations from './filters.mutationlist'

/* eslint no-param-reassign: [
  "error", { "props": true, "ignorePropertyModificationsFor": ["state"] }
] */
export default {
  // [mutations.CHANGE_CATEGORIES]: (state, payload) => {
  //   Vue.set(state, 'categories', payload)
  // },
  // [mutations.VISIBLE_GRID]: (state, payload) => {
  //   Vue.set(state, 'visible', payload)
  // },
  // [mutations.PAN_EVENT]: (state, payload) => {
  //   Vue.set(state, 'event', { ...state.event, pan: payload })
  // },
  // [mutations.SWIPE_LEFT_EVENT]: (state, payload) => {
  //   state.event.swipeLeft = { ...state.event.swipeLeft, ...payload }
  // },
  // [mutations.SWIPE_RIGHT_EVENT]: (state, payload) => {
  //   state.event.swipeRight = { ...state.event.swipeRight, ...payload }
  // },
  // [mutations.TOGGLE_LEFT_MENU]: (state, payload) => {
  //   state.menuLeft = { ...state.menuLeft, ...payload }
  // },
  // [mutations.TOGGLE_MENU]: (state, payload) => {
  //   state.menu = { ...state.menu, ...payload }
  // },
  // [mutations.TOGGLE_RIGHT_MENU]: (state, payload) => {
  //   state.menuRight = { ...state.menuRight, ...payload }
  // },
  [mutations.SET_INIT]: (state, payload) => {
    Vue.set(state, 'init', payload)
  },
  // [mutations.FACEBOOK_INITIALIZED]: (state, payload) => {
  //   state.social = { ...state.social, facebookInit: payload }
  // },
  // [mutations.GOOGLE_INITIALIZED]: (state, payload) => {
  //   state.social = { ...state.social, googleInit: payload }
  // },
  [mutations.SET_TEMP]: (state, { key, value }) => {
    Vue.set(state, key, value)
  }
}
