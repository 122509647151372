import Vue from 'vue'
import Vuex from 'vuex'
import fpsStore from '@fps/vue-toolkit/dist/store'
import app from './modules/app'
import errorLog from './modules/errorLog'
import tagsView from './modules/tagsView'
import temp from './modules/temp'
import filters from './modules/filters'
import auth from './modules/auth'
import state from './state'
import mutations from './mutations'
import getters from './getters'
import { cloneDeep } from 'lodash-es'

Vue.use(Vuex)

export function getInitialState() {
  return cloneDeep({ ...fpsStore.state, ...state })
}

const store = new Vuex.Store({
  modules: {
    app,
    errorLog,
    // permission,
    tagsView,
    temp: {
      state: { ...fpsStore.modules.temp.state, ...temp.state },
      actions: { ...fpsStore.modules.temp.actions, ...temp.actions },
      mutations: { ...fpsStore.modules.temp.mutations, ...temp.mutations },
      getters: { ...fpsStore.modules.temp.getters, ...temp.getters }
    },
    filters,
    auth: {
      ...fpsStore.modules.auth,
      actions: { ...fpsStore.modules.auth.actions, ...auth.actions },
      getters: { ...fpsStore.modules.auth.getters, ...auth.getters }
    }
    // user
  },
  state: getInitialState(),
  actions: fpsStore.actions,
  mutations: { ...fpsStore.mutations, ...mutations },
  getters: { ...fpsStore.getters, ...getters }
})

export default store
