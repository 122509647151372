import mutations from './filters.mutationlist'

export default {
//   fetchCategories: ({ state, commit }) => {
//     if (state.categories) {
//       return Promise.resolve(state.categories)
//     }
//     return new Promise((resolve, reject) => {
//       this.http.get('categories')
//         .then((response) => {
//           if (response && response.data) {
//             commit(mutations.CHANGE_CATEGORIES, response && response.data ? response.data : [])
//           }
//           resolve(state.categories)
//         }, (error) => {
//           reject(error)
//         })
//     })
//   },
//
  saveResource: ({ commit, state }, { name, type, payload }) => {
    commit(mutations.SET_TEMP, { key: name, value: { ...state[name], [type]: payload }})
  }
}
