import router from '@/router'
import mutations from '@fps/vue-toolkit/dist/store/auth/auth.mutationlist'
import baseActions from '@fps/vue-toolkit/dist/store/auth/auth.actions'

export default {
  fetchUser: ({ commit }) => new Promise(
    (resolve, reject) => router.app.$http.get('user/me', {
      params: {
        file: true,
        profile: true,
        userSubscription: true,
        primaryDoctor: true,
        primaryAddress: true,
        defaultStatuses: true,
        roles: true
      }
    })
      .then((response) => {
        if (response && response.data) {
          commit(mutations.SET_USER, response.data)
        }
        resolve(response.data)
      }, (error) => {
        reject(error)
      })
  ),

  resetPassword: ({ commit }, { credentials, url }) => new Promise(
    (resolve, reject) => router.app.$http.post(url || 'auth/password/email', credentials)
      .then((response) => {
        if (response.data) {
          resolve(response)
        } else {
          reject(response)
        }
      }, (error) => {
        commit(mutations.LOGOUT)
        reject(error)
      }),
  ),

  logout: (store) => new Promise((resolve, reject) => {
    const reset = () => {
      setTimeout(() => {
        store.commit('RESET')
      })
    }

    baseActions.logout.call(this, store)
      .then((response) => {
        resolve(response)
        reset()
      }, (response) => {
        reject(response)
        reset()
      })
  })
}
