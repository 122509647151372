import Vue from 'vue'
import Router from 'vue-router'
import authGuard from './guards/auth'
import userGuard from './guards/user'

Vue.use(Router)

/* Layout */
import Layout from '@/views/layout/Layout'

/* Router Modules */
// import componentsRouter from './modules/components'
// import chartsRouter from './modules/charts'
// import tableRouter from './modules/table'
// import nestedRouter from './modules/nested'

/** note: Submenu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin','editor']     will control the page roles (you can set multiple roles)
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
    noCache: true                if true ,the page will no be cached(default is false)
  }
**/
export const constantRouterMap = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/welcome',
    component: () => import('@/pages/Welcome'),
    hidden: true
  },
  {
    path: '/login',
    component: () => import('@/pages/Login'),
    hidden: true
  },
  {
    path: '/register',
    component: () => import('@/pages/Register'),
    hidden: true
  },
  {
    path: '/reset-password',
    component: () => import('@/pages/ResetPassword'),
    hidden: true
  },
  {
    path: '/reset/:token?',
    component: () => import('@/pages/Reset'),
    hidden: true
  },
  {
    path: '/auth-redirect',
    component: () => import('@/views/login/authredirect'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/errorPage/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/errorPage/401'),
    hidden: true
  },

  {
    path: '/dashboard',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        meta: { guards: [authGuard] },
        component: () => import('@/pages/Dashboard')
      }
    ],
    hidden: true
  },
  {
    path: '/tenants',
    component: Layout,
    redirect: { name: 'My Tenants' },
    meta: { icon: 'shield', admin: true },
    children: [
      {
        path: 'my-tenants',
        component: () => import('@/pages/MyTenants'),
        name: 'My Tenants',
        meta: { title: 'My Tenants', hidden: true, noCache: true, guards: [authGuard] }
      }
    ]
  },
  {
    path: '/tenant',
    component: Layout,
    redirect: '/tenant/new-tenant',
    children: [
      {
        path: '/new-tenant',
        component: () => import('@/pages/NewTenant'),
        meta: { guards: [authGuard] }
      },
      {
        path: ':id',
        component: () => import('@/pages/NewTenant'),
        meta: { guards: [authGuard] }
      }
    ],
    hidden: true
  },
  {
    path: '/patients',
    component: Layout,
    redirect: { name: 'My Patients' },
    meta: { icon: 'user-avatar', subscription: true },
    children: [
      {
        path: 'my-patients',
        component: () => import('@/pages/MyPatients'),
        name: 'My Patients',
        meta: { title: 'My Patients', hidden: true, noCache: true, guards: [authGuard] }
      },
      {
        path: 'archived-patients',
        component: () => import('@/pages/ArchivedPatients'),
        name: 'Archived Patients',
        meta: { title: 'Archived Patients', noCache: true, guards: [authGuard] }
      }
    ]
  },
  {
    path: '/patient',
    component: Layout,
    redirect: '/patient/new-patient',
    children: [
      {
        path: '/new-patient',
        component: () => import('@/pages/NewPatients'),
        meta: { guards: [authGuard] }
      },
      {
        path: ':id',
        component: () => import('@/pages/NewPatients'),
        meta: { guards: [authGuard] }
      }
    ],
    hidden: true
  },
  {
    path: '/new-doctor',
    component: Layout,
    redirect: '/new-doctor',
    children: [
      {
        path: '/new-doctor',
        component: () => import('@/pages/settings/NewDoctor'),
        meta: { guards: [authGuard] }
      }
    ],
    hidden: true
  },
  {
    path: '/new-location',
    component: Layout,
    redirect: '/new-location',
    children: [
      {
        path: '/new-location',
        component: () => import('@/pages/settings/NewLocation'),
        meta: { guards: [authGuard] }
      }
    ],
    hidden: true
  },
  {
    path: '/new-status',
    component: Layout,
    redirect: '/new-status',
    children: [
      {
        path: '/new-status',
        component: () => import('@/pages/settings/NewStatus'),
        meta: { guards: [authGuard] }
      }
    ],
    hidden: true
  },
  {
    path: '/settings',
    component: Layout,
    redirect: { name: 'My Account' },
    meta: { icon: 'settings' },
    children: [
      {
        path: 'my-account',
        component: () => import('@/pages/settings/MyAccount'),
        name: 'My Account',
        meta: { title: 'My Account', hidden: false, noCache: true, guards: [authGuard] }
      },
      {
        path: 'doctors',
        component: () => import('@/pages/settings/Doctors'),
        name: 'Doctors',
        meta: { title: 'Doctors', noCache: true, hidden: false, guards: [authGuard], subscription: true }
      },
      {
        path: 'locations',
        component: () => import('@/pages/settings/Locations'),
        name: 'Locations',
        meta: { title: 'Locations', noCache: true, hidden: false, guards: [authGuard], subscription: true }
      },
      {
        path: 'statuses',
        component: () => import('@/pages/settings/Statuses'),
        name: 'Statuses',
        meta: { title: 'Statuses', noCache: true, hidden: false, guards: [authGuard], subscription: true }
      },
      {
        path: 'appointment-type',
        component: () => import('@/pages/settings/AppointmentTypes'),
        name: 'Appointment Types',
        meta: { title: 'Appointment Types', noCache: true, hidden: false, guards: [authGuard], subscription: true }
      },
      {
        path: 'case-review',
        component: () => import('@/pages/settings/CaseReview'),
        name: 'Case Review',
        meta: { title: 'Case Review', noCache: true, hidden: false, guards: [authGuard], subscription: true }
      }
    ]
  },

  {
    path: '/subscription',
    component: Layout,
    redirect: { name: 'Subscription' },
    meta: { icon: 'coin' },
    children: [
      {
        path: 'add-subscription',
        // component: () => import('@/pages/AddSubscription'),
        component: () => import('@/pages/SubscriptionsLayout'),
        name: 'Subscription',
        meta: { title: 'Subscription', noCache: true, guards: [authGuard] }
      },
      {
        path: 'new-subscription/:id',
        component: () => import('@/pages/NewSubscription'),
        name: 'Subscription',
        meta: { title: 'Subscription', noCache: true, guards: [authGuard] },
        hidden: true
      },
      {
        path: 'customer-service',
        component: () => import('@/pages/CustomerService'),
        name: 'Customer Service',
        meta: { title: 'Customer Service', noCache: true, guards: [authGuard] }
      },
      {
        path: 'privacy-policy',
        component: () => import('@/pages/Policy'),
        name: 'Privacy Policy',
        meta: { title: 'Privacy Policy', noCache: true, guards: [authGuard] }
      },
      {
        path: 'terms-&-conditions',
        component: () => import('@/pages/Terms'),
        name: 'Terms & Conditions',
        meta: { title: 'Terms & Conditions', noCache: true, guards: [authGuard] }
      }
    ]
  },
  {
    path: '*',
    component: () => import('@/pages/Dispatcher'),
    hidden: true
  },
  {
    path: 'not-found',
    component: Layout
  }
]

const router = new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap,
  defaultGuards: [userGuard],
  defaultRoute: '/'
})

export default router
