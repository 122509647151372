export default {
  // CHANGE_CATEGORIES: 'CHANGE_CATEGORIES',
  // VISIBLE_GRID: 'VISIBLE_GRID',
  // PAN_EVENT: 'PAN_EVENT',
  // SWIPE_LEFT_EVENT: 'SWIPE_LEFT_EVENT',
  // SWIPE_RIGHT_EVENT: 'SWIPE_RIGHT_EVENT',
  // TOGGLE_LEFT_MENU: 'TOGGLE_LEFT_MENU',
  // TOGGLE_MENU: 'TOGGLE_MENU',
  // TOGGLE_RIGHT_MENU: 'TOGGLE_RIGHT_MENU',
  SET_INIT: 'SET_INIT',
  FILTER_SET: 'FILTER_SET',
  // FACEBOOK_INITIALIZED: 'FACEBOOK_INITIALIZED',
  // GOOGLE_INITIALIZED: 'GOOGLE_INITIALIZED',
  SET_TEMP: 'SET_TEMP'
}
