<template>
  <div v-if="!item.hidden && item.children" class="menu-wrapper">
    <template
      v-if="hasOneShowingChild(item.children,item) && (!item.children[0].children||item.children[0].noShowingChildren)&&!item.alwaysShow">
      <app-link :to="resolvePath(item.children[0].path)">
        <el-menu-item
          :index="resolvePath(item.children[0].path)"
          :class="{'submenu-title-noDropdown':!isNest}"
          @click="closeSidebar">
          <hamburger v-if="item.children[0].meta" :icon="item.children[0].meta.icon||item.meta.icon" class="hamburg-menu-item"/>
        </el-menu-item>
      </app-link>
    </template>
    <el-submenu
      v-else
      ref="submenu"
      :index="resolvePath(item.path)"
      :show-timeout="2600"
      deactivated
      popper-class="submenu-animation">
      <template slot="title">
        <hamburger v-if="item.meta" :icon="item.meta.icon" :is-sub-menu="true" class="hamburg-menu-item"/>
      </template>
      <div class="submenu-wrapper">
        <el-menu-item :index="resolvePath(item.children[0].path)" class="close-submenu-button" @click="closeSidebar">
          <i class="icon-arrow-back"/>
        </el-menu-item>
        <div class="submenu-logo">
          <img src="@/assets/custom-theme/logo.png" alt="">
        </div>
      </div>
      <template v-for="child in item.children">
        <app-link v-if="!child.hidden && (!child.meta || ((!child.meta.admin || canUseAdminDashboard) && (!child.meta.subscription || canUseDashboard)))" :to="resolvePath(child.path)" :key="child.name">
          <el-menu-item v-if="child.meta" :index="resolvePath(child.path)" @click="closeSidebar">
            <item v-if="child.meta" :icon="child.meta.icon" :title="generateTitle(child.meta.title)"/>
          </el-menu-item>
        </app-link>
      </template>
    </el-submenu>
  </div>
</template>

<script>
import Vue from 'vue'
import path from 'path'
import { mapGetters } from 'vuex'
import { generateTitle } from '@/utils/i18n'
import { isExternal } from '@/utils'
import Item from './Item'
import AppLink from './Link'
import FixiOSBug from './FixiOSBug'
import Hamburger from '../../../../components/Hamburger/index'

export default {
  name: 'SidebarItem',
  components: { Hamburger, Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      onlyOneChild: null,
      notExcluded: false
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'canUseDashboard',
      'canUseAdminDashboard'
    ])
  },
  methods: {
    closeSidebar() {
      if (this.sidebar.opened) {
        this.sidebar.opened = false
      }
    },
    hasOneShowingChild(children, parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          // this.onlyOneChild = item
          return true
        }
      })

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        // this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath(routePath) {
      if (this.isExternalLink(routePath)) {
        return routePath
      }
      return path.resolve(this.basePath, routePath)
    },
    isExternalLink(routePath) {
      return isExternal(routePath)
    },
    generateTitle
  }
}
</script>
