/* eslint-disable prefer-promise-reject-errors */
import store from '@/store'

export default {
  canActivate() {
    return new Promise((resolve, reject) => {
      if (!store.state.auth.token || store.state.auth.user) {
        // If not authenticated or user exists, continue
        resolve()
      } else if (!this.isGettingUser) {
        // If authenticated and no user data, get user and continue
        this.isGettingUser = true
        store.dispatch('fetchUser')
          .then(() => {
            resolve()
            this.isGettingUser = false
          }, () => {
            reject()
            this.isGettingUser = false
          })
      } else {
        reject()
      }
    })
  }
}
